import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components, useServices } from 'cng-web-lib'
import { Grid, Typography, } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import { NaAceHwyPOEPortAutocompleteField } from 'src/components/na/autocomplete/codemaintenance'
import CngSection from 'src/components/cngcomponents/CngSection'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'

const {
    form: {
        field: {
            CngSelectField,
            CngTextField,
            CngDateField,
            CngTimeField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem
} = components

function TripInformationSection(props) {
    const { disabled, shouldHideMap, user, id, editPage, trigger } = props
    const { fetchRecords } = useServices();
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const [propagateInbondNum, setPropagateInbondNum] = useState('');
    const [unusedIbondNum, setUnusedIbondNum] = useState('');
    const [onChangeScacCode, setOnChangeScacCode] = useState(false)
    const [scacList, setScacList] = useState([]);
    const [tripNumAutoGenerated, setTripNumAutoGenerated] = useState(false);

    const templateFlag = useWatch({ name: 'templateFlag' })
    const carrierScac = useWatch({ name: 'carrierScac' })
    const tripNo = useWatch({ name: 'tripNo' })
    const status = useWatch({ name: 'status' })
    const firstExpectedPort = useWatch({ name: 'firstExpectedPort' })
    const autoInbondNumUser = useWatch({ name: 'autoInbondNumUser' })

    useEffect(() => {
        var scacCode = '';
        var module = '';
        var jsonArray = [];
        if (user && user.scacs) {
            Object.entries(user.scacs).forEach((item) => {
                for (const key in item[1]) {
                    if (key == 'scacCode') {
                        scacCode = item[1][key];
                    }
                    if (key == 'module') {
                        module = item[1][key];
                    }
                }

                if (module == 'ACEHW') {
                    var jsonObject = { text: scacCode, value: scacCode };
                    jsonArray.push(jsonObject);
                }
            })
            setScacList(jsonArray);
        }
    }, [user]);

    useEffect(() => {
        if (user && user.userAutoTripNos) {
            let flag = user.userAutoTripNos[0];
            setTripNumAutoGenerated(flag && flag.autoTripNo === 'true' && flag.module.includes('ACEHWY'));
        }
    }, [user])

    useEffect(() => {
        fetchRecords.execute(AceHighwayShipmentApiUrls.GET_PROPAGATE_INBOND_NUMBER, '', (res) => {
            if (autoInbondNumUser) {
                console.log('Next Propagate Inbond Num : ' + JSON.stringify(res.inbondNumber));
                setPropagateInbondNum(res.inbondNumber);
                console.log('Unused Inbond Num : ' + JSON.stringify(res.unusedInbondNumber));
                setUnusedIbondNum(res.unusedInbondNumber);
            }
        })
    }, [autoInbondNumUser])

    useEffect(() => {
        if (carrierScac !== "" && tripNo === "") {
            if (id === undefined && autoInbondNumUser) {
                if (unusedIbondNum === 0) {
                    setValue('tripNo', carrierScac.toUpperCase())
                } else {
                    setValue('tripNo', carrierScac.toUpperCase() + propagateInbondNum)
                }
            } else if (tripNumAutoGenerated) {
                setValue('tripNo', carrierScac.toUpperCase() + getCurrentDateTime())
            }
            trigger()
        }
    }, [new Date()])

    function getCurrentDateTime() {
        let date = new Date();
        return date.toISOString().split('.')[0].replace(/[^\d]/gi, '')
    }

    return (
        <Grid item xs={12}>
            <CngSection
                title='Trip Information' helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'tripSectionHelper')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }
                id="trip-info"
            >
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6}>
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.carrierScac}>
                                <CngSelectField
                                    required={!templateFlag || !editPage}
                                    name='carrierScac'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'carrierScac')}
                                    disabled={disabled || status === '1000'}
                                    items={scacList}
                                    onChange={(e) => {
                                        if (id === undefined && autoInbondNumUser) {
                                            if (unusedIbondNum === 0 || unusedIbondNum === 50 || unusedIbondNum === 100 || unusedIbondNum === 200 || unusedIbondNum === 500 || unusedIbondNum === 1000) {
                                                window.confirm('In-bond number sequence running out, ' + unusedIbondNum + ' left please provide additional numbers.')
                                            }
                                            if (unusedIbondNum === 0) {
                                                setValue('tripNo', e.target.value.toUpperCase())
                                            } else {
                                                setValue('tripNo', e.target.value.toUpperCase() + propagateInbondNum)
                                            }
                                        } else if (tripNumAutoGenerated) {
                                            setValue('tripNo', e.target.value.toUpperCase() + getCurrentDateTime())
                                        } else if (tripNo === '') {
                                            setOnChangeScacCode(true);
                                            setValue('tripNo', e.target.value.toUpperCase())
                                        } else {
                                            if (onChangeScacCode || editPage) {
                                                var removedPrefix = tripNo.substring(4);
                                                setValue('tripNo', e.target.value.toUpperCase() + removedPrefix)
                                            } else {
                                                setOnChangeScacCode(true);
                                                setValue('tripNo', e.target.value.toUpperCase() + tripNo)
                                            }
                                        }
                                    }}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={9} shouldHide={shouldHideMap.tripNo}>
                                <CngTextField
                                    size='small'
                                    required={!templateFlag || !editPage}
                                    name='tripNo'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'tripNo')}
                                    disabled={tripNumAutoGenerated || status === '1000'}
                                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 25 }}
                                    onBlur={(e) => { setValue('tripNo', e.target.value.trim().toUpperCase()) }}
                                />
                            </CngGridItem>
                        </Grid>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalDateOnly}>
                        <CngDateField
                            size='small'
                            required
                            name='arrivalDate'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'arrivalDateOnly')}
                            format='YYYY-MM-DD'
                            disabled={disabled || status === '1000'}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalTime}>
                        <CngTimeField
                            size='small'
                            required
                            name='arrivalTime'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'arrivalTime')}
                            format='HH:mm'
                            disabled={disabled}
                            ampm={false}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transitIndicator}>
                        <CngCodeMasterAutocompleteField
                            size='small'
                            required
                            name='transitIndicator'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'transitIndicator')}
                            disabled={disabled}
                            codeType='ACE_HW_TRANSPORT_INDICATOR'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstExpectedPort}>
                        <NaAceHwyPOEPortAutocompleteField
                            size='small'
                            required
                            name='firstExpectedPort'
                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'firstExpectedPort')}
                            disabled={disabled}
                            inputProps={{ maxLength: 30 }}
                            key={firstExpectedPort}
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default TripInformationSection
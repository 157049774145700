import React, { useEffect, useState, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components, constants } from 'cng-web-lib'
import { Grid, Box, Divider, Typography, IconButton, Tooltip, InputAdornment } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import { NaConveyanceMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import CngSection from 'src/components/cngcomponents/CngSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import CheckboxButton from 'src/components/button/CheckboxButton'
import ToggleButtonGroup from 'src/components/aciacehighway/ToggleButtonGroup'
import ConveyanceTemplateDialog from '../ConveyanceTemplateDialog'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import Alert from 'src/components/Alert'
import HelperText from 'src/views/common/HelperText'


const {
    button: { CngIconButton },
    form: {
        field: {
            CngCheckboxField,
            CngTextField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem
} = components

const { filter: { EQUAL } } = constants

function TruckSection(props) {
    const { disabled, shouldHideMap, showNotification, licensePlateCount, setLicensePlateCount } = props
    const { setValue, getValues, trigger } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const [conveyanceTemplateDialog, setConveyanceTemplateDialog] = useState(false)

    const aceFlag = useWatch({ name: 'aceFlag' })
    const saveTruckFlag = useWatch({ name: 'saveTruckFlag' })
    const conveyanceType = useWatch({ name: 'conveyanceType' })
    const iitCodes = useWatch({ name: ['iitCode1', 'iitCode2', 'iitCode3', 'iitCode4'] })
    const sealNos = useWatch({ name: ['sealNo1', 'sealNo2', 'sealNo3', 'sealNo4', 'sealNo5', 'sealNo6', 'sealNo7', 'sealNo8', 'sealNo9'] })

    useEffect(() => {
        if (aceFlag) {
            setValue('vin', '')
        } else {
            setValue('aceId', '')
        }
    }, [aceFlag])

    function handleClearLicensePlates() {
        setLicensePlateCount(1)
        setValue('licensePlateNo1', '')
        setValue('licensePlateState1', '')
        setValue('licensePlateCountry1', '')
        setValue('licensePlateNo2', '')
        setValue('licensePlateState2', '')
        setValue('licensePlateCountry2', '')
        setValue('licensePlateNo3', '')
        setValue('licensePlateState3', '')
        setValue('licensePlateCountry3', '')
        setValue('licensePlateNo4', '')
        setValue('licensePlateState4', '')
        setValue('licensePlateCountry4', '')
    }

    function handleApplyConveyanceTemplate(template) {
        if (template) {
            const {
                conveyanceID,
                aceId,
                conveyanceNo,
                conveyanceType,
                email,
                licensePlates,
                transponderNo,
                vinNo
            } = template

            if (aceId) {
                setValue('aceFlag', true)
                setValue('aceId', aceId)
            } else {
                setValue('aceFlag', false)
            }
            setValue('truckId', conveyanceID || '')
            setValue('conveyanceId', conveyanceID || '')
            setValue('conveyanceNo', conveyanceNo || '')

            setValue('email', email || '')
            setValue('conveyanceType', conveyanceType || '')
            setValue('transponderId', transponderNo || '')
            setValue('vin', vinNo || '')

            if (licensePlates.length > 0) {
                licensePlates.forEach((licensePlate, index) => {
                    setValue(`licensePlateNo${index + 1}`, licensePlate.licensePlateNo || '')
                    setValue(`licensePlateState${index + 1}`, licensePlate.state || '')
                    setValue(`licensePlateCountry${index + 1}`, licensePlate.countryofRegistration || '')
                })

                setLicensePlateCount(licensePlates.length)
            } else {
                handleClearLicensePlates()
            }

            showNotification('success', 'Template applied successfully.')
        } else {
            setValue('truckId', '') //for conveyance master in shared
            setValue('conveyanceId', '') //for conveyance in ace highway manifest
            setValue('aceId', '')
            setValue('conveyanceNo', '')
            setValue('email', '')
            setValue('conveyanceType', '')
            setValue('transponderId', '')
            setValue('vin', '')

            handleClearLicensePlates()
        }

        trigger()
    }

    function handleDuplicatePlate(index) {
        setValue(
            `licensePlateNo${licensePlateCount + 1}`,
            getValues(`licensePlateNo${index}`),
            { shouldTouch: true }
        )
        setValue(
            `licensePlateCountry${licensePlateCount + 1}`,
            getValues(`licensePlateCountry${index}`)
        )
        setValue(
            `licensePlateState${licensePlateCount + 1}`,
            getValues(`licensePlateState${index}`)
        )

        setLicensePlateCount((prev) => prev + 1)
    }

    function handleRemoveLicensePlate(removedIndex) {
        // Skips when removed index is the last index
        if (removedIndex !== licensePlateCount) {
            for (let i = removedIndex; i < licensePlateCount; i++) {
                // Replace removed plate's values with the next plate's
                setValue(`licensePlateNo${i}`, getValues(`licensePlateNo${i + 1}`), {
                    shouldValidate: true
                })
                setValue(
                    `licensePlateCountry${i}`,
                    getValues(`licensePlateCountry${i + 1}`)
                )
                setValue(
                    `licensePlateState${i}`,
                    getValues(`licensePlateState${i + 1}`)
                )
            }
        }

        // Remove last plate's values
        setValue(`licensePlateNo${licensePlateCount}`, '', { shouldValidate: true })
        setValue(`licensePlateCountry${licensePlateCount}`, '')
        setValue(`licensePlateState${licensePlateCount}`, '')

        setLicensePlateCount((prev) => prev - 1)
    }


    return (
        <Grid item xs={12}>
            <CngSection
                title='Truck (Conveyance)' helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'truckSectionHelper')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }
                id="truck-conveyance"
            >

                <ConveyanceTemplateDialog
                    open={conveyanceTemplateDialog}
                    onApplyTemplate={handleApplyConveyanceTemplate}
                    onClose={() => setConveyanceTemplateDialog(false)}
                />

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md='auto'>
                                <ToggleButtonGroup
                                    exclusive
                                    onChange={(_, value) => {
                                        if (value === null) return

                                        setValue('aceFlag', value)

                                        if (value) {
                                            setValue('vin', '')
                                        } else {
                                            setValue('aceId', '')
                                        }
                                    }}
                                    options={[
                                        { label: 'With ACE no.', value: true },
                                        { label: 'Without ACE no.', value: false }
                                    ]}
                                    value={aceFlag}
                                />
                            </Grid>
                            <Grid item xs={12} md>
                                <Alert severity='info' icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}>
                                    <strong>NOTE:</strong> {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'conveyanceRemark')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.saveTruckFlag}>
                                <CngCheckboxField
                                    label={
                                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                                            {getTranslatedText('ACE_HIGHWAY_MANIFEST', 'saveTruckFlag')}
                                        </Typography>
                                    }
                                    name='saveTruckFlag'
                                    disabled={disabled}
                                    onChange={(e) => setValue('saveTruckFlag', e.target.checked)}
                                    size='small'
                                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                                    value={!saveTruckFlag ? false : true}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap.truckId}>
                                <CngTextField
                                    name='truckId'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'truckId')}
                                    disabled={disabled || !saveTruckFlag}
                                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 15 }}
                                    onBlur={(e) => { setValue('truckId', e.target.value.toUpperCase()) }}
                                    size='small'
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4}>
                                <NaConveyanceMasterAutoCompleteField
                                    name='fetchTruck'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'fetchTruck')}
                                    disabled={disabled}
                                    onChange={(_, options) => handleApplyConveyanceTemplate(options.data)}
                                    size='small'
                                    fullWidth
                                    disableClearable
                                    textFieldProps={{
                                        InputProps: {
                                            customEndAdornment: () => (
                                                <InputAdornment position='end' style={{ marginTop: -16 }}>
                                                    <IconButton onClick={(event) => {
                                                        event.stopPropagation()
                                                        setConveyanceTemplateDialog(true)
                                                    }}>
                                                        <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                                            <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                                                        </Box>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    }}
                                    forcePopupIcon={false}
                                    lookupProps={{
                                        filters: [{
                                            field: 'partyId',
                                            operator: EQUAL,
                                            value: FileForUserGetPartyId()
                                        }]
                                    }}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateNo1`]}>
                                <CngTextField
                                    size='small'
                                    required
                                    name={`licensePlateNo1`}
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licensePlateNo1')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateCountry1`]}>
                                <CngCodeMasterAutocompleteField
                                    size='small'
                                    required
                                    name={`licensePlateCountry1`}
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licensePlateCountry1')}
                                    disabled={disabled}
                                    codeType='ACIHWY_MANIFEST_COUNTRY'
                                    key={getValues('licensePlateCountry1')}
                                    onChange={() => setValue(`licensePlateState1`, '')}
                                    lookupProps={{
                                        label: (record) => {
                                            return `${record.code};${record.descriptionEn}`
                                        }
                                    }}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateState1`]}>
                                <Grid container spacing={1} alignItems='center'>
                                    <CngGridItem xs>
                                        <NaAceStateCodeAutoCompleteField
                                            size='small'
                                            required
                                            name={`licensePlateState1`}
                                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'licensePlateState1')}
                                            disabled={disabled}
                                            countryCode={getValues(`licensePlateCountry1`)}
                                            key={getValues('licensePlateCountry1')}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.conveyanceNo}>
                                <CngTextField
                                    size='small'
                                    name='conveyanceNo'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'conveyanceNo')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.conveyanceType}>
                                <CngCodeMasterAutocompleteField
                                    size='small'
                                    required
                                    name='conveyanceType'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'conveyanceType')}
                                    disabled={disabled}
                                    codeType='ACE_HW_CONVEYANCE_TYPE'
                                    key={conveyanceType}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={!aceFlag || shouldHideMap.aceId}>
                                <CngTextField
                                    size='small'
                                    required
                                    name='aceId'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'aceId')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={true}>
                                <CngTextField
                                    size='small'
                                    name='conveyanceId'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'conveyanceId')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} md={4} shouldHide={aceFlag || shouldHideMap.vin}>
                                <CngTextField
                                    size='small'
                                    required
                                    name='vin'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'vin')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={aceFlag || shouldHideMap.dotNo}>
                                <CngTextField
                                    size='small'
                                    name='dotNo'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'dotNo')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={aceFlag || shouldHideMap.transponderId}>
                                <CngTextField
                                    size='small'
                                    name='transponderId'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'transponderId')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.email}>
                                <CngTextField
                                    size='small'
                                    name='email'
                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'email')}
                                    disabled={disabled}
                                />
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </Grid>

                <CollapsibleSection defaultExpanded={licensePlateCount > 1} title='Additional License Plates' style={{ marginTop: 16 }}>
                    <Grid container spacing={1}>
                        {[...Array(licensePlateCount - 1)]
                            .map((_, index) => index + 2)
                            .map((count, index, array) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateNo${count}`]}>
                                                <CngTextField
                                                    size='small'
                                                    name={`licensePlateNo${count}`}
                                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', `licensePlateNo${count}`)}
                                                    disabled={disabled}
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateCountry${count}`]}>
                                                <CngCodeMasterAutocompleteField
                                                    size='small'
                                                    name={`licensePlateCountry${count}`}
                                                    label={getTranslatedText('ACE_HIGHWAY_MANIFEST', `licensePlateCountry${count}`)}
                                                    disabled={disabled}
                                                    codeType='ACIHWY_MANIFEST_COUNTRY'
                                                    key={getValues('licensePlateCountry' + `${count}`)}
                                                    onChange={() => setValue(`licensePlateState${count}`, '')}
                                                    lookupProps={{
                                                        label: (record) => {
                                                            return `${record.code};${record.descriptionEn}`
                                                        }
                                                    }}
                                                />
                                            </CngGridItem>
                                            <CngGridItem xs={12} md={4} shouldHide={shouldHideMap[`licensePlateState${count}`]}>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <CngGridItem xs>
                                                        <NaAceStateCodeAutoCompleteField
                                                            size='small'
                                                            name={`licensePlateState${count}`}
                                                            label={getTranslatedText('ACE_HIGHWAY_MANIFEST', `licensePlateState${count}`)}
                                                            disabled={disabled}
                                                            countryCode={getValues(`licensePlateCountry${count}`)}
                                                            key={getValues(`licensePlateCountry${count}`)}
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs='auto'>
                                                        <CngIconButton
                                                            disabled={licensePlateCount > 3}
                                                            type='outlined'
                                                            icon={['fal', 'plus']}
                                                            onClick={() => setLicensePlateCount((prev) => prev + 1)}
                                                            size='small'
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs='auto'>
                                                        <CngIconButton
                                                            disabled={licensePlateCount > 3}
                                                            type='outlined'
                                                            icon={['fal', 'copy']}
                                                            onClick={() => handleDuplicatePlate(count)}
                                                            size='small'
                                                        />
                                                    </CngGridItem>
                                                    <CngGridItem xs='auto'>
                                                        <CngIconButton
                                                            type='outlined'
                                                            icon={['fal', 'trash']}
                                                            onClick={() => handleRemoveLicensePlate(count)}
                                                            size='small'
                                                        />
                                                    </CngGridItem>
                                                </Grid>
                                            </CngGridItem>
                                        </Grid>
                                    </Grid>
                                    {array.length !== index + 1 && (
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    )}
                                </React.Fragment>

                            ))}

                        {licensePlateCount < 2 && (
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs='auto'>
                                        <Tooltip placement='bottom' title='Add new'>
                                            <span>
                                                <CngIconButton
                                                    icon={['fal', 'plus']}
                                                    onClick={() => setLicensePlateCount((prev) => prev + 1)}
                                                    size='small'
                                                    type='outlined'
                                                />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CollapsibleSection>

                <CollapsibleSection defaultExpanded={sealNos.filter((sealNo) => sealNo).length > 0} title='Seal Nos.' style={{ marginTop: 16 }}>
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo1}>
                            <CngTextField
                                size='small'
                                name='sealNo1'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo1')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo2}>
                            <CngTextField
                                size='small'
                                name='sealNo2'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo2')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo3}>
                            <CngTextField
                                size='small'
                                name='sealNo3'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo3')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo4}>
                            <CngTextField
                                size='small'
                                name='sealNo4'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo4')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo5}>
                            <CngTextField
                                size='small'
                                name='sealNo5'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo5')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo6}>
                            <CngTextField
                                size='small'
                                name='sealNo6'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo6')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo7}>
                            <CngTextField
                                size='small'
                                name='sealNo7'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo7')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo8}>
                            <CngTextField
                                size='small'
                                name='sealNo8'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo8')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={4} lg={3} shouldHide={shouldHideMap.sealNo9}>
                            <CngTextField
                                size='small'
                                name='sealNo9'
                                label={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'sealNo9')}
                                disabled={disabled}
                            />
                        </CngGridItem>
                    </Grid>
                </CollapsibleSection>

                <CollapsibleSection
                    defaultExpanded={iitCodes.filter((iitCode) => iitCode).length > 0}
                    title={
                        <Box display='flex' style={{ gap: 8 }}>
                            <HelperText
                                helperText={[
                                    getTranslatedText('ACE_HIGHWAY_MANIFEST', 'iitCode1'),
                                    getTranslatedText('ACE_HIGHWAY_MANIFEST', 'iitCode2'),
                                    getTranslatedText('ACE_HIGHWAY_MANIFEST', 'iitCode3'),
                                    getTranslatedText('ACE_HIGHWAY_MANIFEST', 'iitCode4')
                                ].join('<br/>')}
                            />
                            <Typography variant='inherit' style={{ fontWeight: 700 }}>
                                Instruments of International Traffic - IIT
                            </Typography>
                        </Box>
                    }
                    style={{ marginTop: 16 }}
                >
                    <Grid container spacing={1}>
                        <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.iitCode1}>
                            <CheckboxButton
                                name='iitCode1'
                                label='EC'
                                checked={iitCodes[0]}
                                disabled={disabled}
                                onChange={(e) => setValue('iitCode1', e.target.checked)}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.iitCode2}>
                            <CheckboxButton
                                name='iitCode2'
                                label='EI'
                                checked={iitCodes[1]}
                                disabled={disabled}
                                onChange={(e) => setValue('iitCode2', e.target.checked)}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.iitCode3}>
                            <CheckboxButton
                                name='iitCode3'
                                label='MC'
                                checked={iitCodes[2]}
                                disabled={disabled}
                                onChange={(e) => setValue('iitCode3', e.target.checked)}
                            />
                        </CngGridItem>
                        <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap.iitCode4}>
                            <CheckboxButton
                                name='iitCode4'
                                label='MI'
                                checked={iitCodes[3]}
                                disabled={disabled}
                                onChange={(e) => setValue('iitCode4', e.target.checked)}
                            />
                        </CngGridItem>
                    </Grid>
                </CollapsibleSection>

            </CngSection>
        </Grid >
    )
}

export default TruckSection